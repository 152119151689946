
.link {
  color: #61dafb;
}

.content {
  background-color: #282c34;
  color: white;
  padding-bottom: 10em;
  padding-left: 1em;
  padding-right: 1em;
}
.try {
  max-width: 500px;
  margin: auto;
}
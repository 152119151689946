
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .heart {
    color: #f45858;
    font-size: 1.3em;
  }
  .credits {
    font-size: 0.8em;
  }
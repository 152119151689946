
.block {
    display: flex;
    flex-direction: row;
    margin: 0 0 3em 0;
}
.head {
    font-size: 1.3em;
    font-weight: 300;
    min-width: 30%;
    max-width: 30%;
}
.text {
    font-size: 1em;
    font-weight: 200;
    border-left: 3px solid #ccc;
    padding-left: 1em;
    margin-left: 1em;
}
.text p {
    margin: 0 0 0.5em 0;
}